import { Meta } from '@/components/layout/Layout';
import { OpportunitySalary } from './opportunity';
import { RelatedSearchLandingPage } from './search';

export enum ImageTypes {
  gatsby = 'gatsby',
  img = 'img',
  html = 'html'
}

export type Image = {
  type: ImageTypes;
  path: string;
  alt: string;
  image?: any;
};

export type FAQ = {
  question: string;
  answer: string;
};

// export type ContentType =
//   | 'article'
//   | 'course'
//   | 'course_provider'
//   | 'event'
//   | 'graduate_profile'
//   | 'opportunity'
//   | 'organisation'
//   | 'video';

export enum ContentType {
  Article = 'article',
  Course = 'course',
  CourseProvider = 'course_provider',
  Event = 'event',
  GraduateProfile = 'graduate_profile',
  Opportunity = 'opportunity',
  Organisation = 'organisation',
  Video = 'video'
}

export type Content = {
  nid: string;
  uuid: string;
  type: string;
  title: string;
  body: string;
  abstract: string;
  path: string;
  featured: boolean;
  promoted: boolean;
  status: boolean;
};

export type Article = Content & {
  thumbnailImage: Image;
  featuredImage: Image;
  categories: string[];
  sectors: string[];
  primaryCtaLink: string;
  primaryCtaLabel: string;
};

export type Organisation = Content & {
  headlineImage: Image;
  logo: Image;
  thumbnailImage: Image;
  featuredImage: Image;
  organisationSize: string;
  graduatePositions: string;
  femalePercentage: string;
  foundedYear: string;
  numberOfJobs: number;
  numberOfEvents: number;
  sectors: string[];
  status: boolean;
};

export type EducationProvider = Content & {
  headlineImage: Image;
  logo: Image;
  thumbnailImage: Image;
  featuredImage: Image;
};

export type Course = Content & {
  location: string;
  duration: string;
  educationProvider: EducationProvider;
  qualificationLetters: string;
  qualifications: string[];
  attendanceTypes: string[];
};

export type GraduateProfile = Content & {
  organisation: Organisation;
  profile_type: string;
  graduateImage: Image;
};

export type Opportunity = Content & {
  organisation: Organisation;
  employmentType: string;
  opportunityType: string;
  salary: OpportunitySalary;
  sectors: string[];
  location: string;
  applicationDeadline: string;
  applicationUrl: string;
  applicationEmail: string;
  applicationOpen: boolean;
  opportunityStartDate: string[];
  benefits: string[];
  degreeSubjects: string[];
  degreeRequirements: string[];
  preregister: boolean;
  sourceOrganisationName: string;
  scraperJob: boolean;
  freeJob: boolean;
  created: Date;
  relatedSearchLandingPages: RelatedSearchLandingPage[];
  relatedGradsim?: {
    featuredImage: string;
    primaryCtaLabel: string;
    primaryCtaLink: string;
    thumbnailImage: string;
    title: string;
  };
  meta: Meta[];
};

export type Event = Content & {
  organisation: Organisation;
  educationProvider: EducationProvider;
  thumbnailImage: Image;
  featuredImage: Image;
  location: string;
  eventType: string;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  eventUrl: string;
  eventRegisterUrl: string;
  eventSeries: boolean;
};

export type Video = Content & {
  organisation: Organisation;
  educationProvider: EducationProvider;
  headlineImage: Image;
  thumbnailImage: Image;
  featuredImage: Image;
  videoUrl: string;
};

export type Pathway = Content & {
  image: Image;
  duration: string;
  organisation: Organisation;
  educationProvider: EducationProvider;
  logo: Image;
  category: string;
};

export type Publication = Content & {
  thumbnailImage: Image;
};
